import React from 'react';
import style from './index.module.scss';
import Button from 'components/button';
import cn from 'classnames';
interface IProps {
  className?: string;
  isTry?: boolean;
  btnType?: any;
  btnWidth?: number;
}
export const IndexView = (props: IProps) => {
  const { className, isTry = true, btnType = 'banner', btnWidth } = props;
  return (
    <section className={cn(style.btnGroup, !!className && className)}>
      {isTry && (
        <Button category={btnType} btnWidth={btnWidth} type="primary" color="green" className={'btnTry'} href="/try">
          立即试用
        </Button>
      )}
      <Button category={btnType} btnWidth={btnWidth} type="primary" href="/contact">
        联系销售
      </Button>
    </section>
  );
};

export default IndexView;
