// loading
import React from 'react';
import css from './style/index.module.scss';

interface IProps {
  visible?: boolean;
  children?: any;
  style?: any;
}

const Loading = (props: IProps) => {
  const { visible = true, children, style } = props;
  return (
    <div className={css.loading} style={style}>
      {visible ? (
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" fill="#44b5ff">
            <path opacity=".25" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4" />
            <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
              <animateTransform attributeName="transform" type="rotate" from="0 16 16" to="360 16 16" dur="0.8s" repeatCount="indefinite" />
            </path>
          </svg>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Loading;
