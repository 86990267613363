import style from './index.module.scss';
import Image, { ImageProps } from 'next/image';
import cn from 'classnames';
import { CSSProperties } from 'react';
interface IProps extends ImageProps {
  styles?: CSSProperties;
}
export default function Home(props: IProps) {
  const { className, styles, layout = 'fill', ...rest } = props;
  return (
    <div className={cn(style.container, !!className && className)} style={styles ?? {}}>
      <Image className={style.img} layout={layout} alt="image" {...rest} />
    </div>
  );
}
