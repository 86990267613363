import Layout from 'components/layout';
import { Carousel, Modal, Row, Col } from 'antd';
import style from 'styles/home/index.module.scss';
import Image from 'components/image';
import CdnImage from 'components/image/cdnImage';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Button from 'components/button';
import ContactBtnGroup from 'components/contact';
import { actionServer } from 'action/index';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import Loading from 'components/loading';
import UseResize from 'utils/hooks/useResize';
import CustomLink from 'components/link';
import { bannerList, coreIntroductions, IBannerList, ICoreIntroductions, ISolution, mapNumbers, mp4Url, muteMp4Url, mutePoster, prefix, solutions } from 'constants/front_page';
import Transition from 'components/transition';
import { JumpIntro } from 'components/jumpIntro';

export default function IndexView(prop: any) {
  const { news } = prop;
  const [videoVisible, setVideoVisible] = useState(false);
  // 记录行业应用解决方案打开item下标
  const [solActiveIdx, setSolActiveIdx] = useState(0);
  const [animationComplete, setAnimationComplete] = useState(false);
  const isFirst = useRef(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  // 轮播图自动切换
  const carouselRef = useRef<any>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // 是否移动端
  const isMobile = UseResize().width < 768;
  const urlPrefix = `${prefix}/${isMobile ? 'M' : 'PC'}/`;

  // 播放视频
  const playVideo = (bool: boolean) => {
    setVideoVisible(bool);
    if (bool) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  };

  // 行业解决方案模块进入视口回调
  const handleAnimationComplete = () => {
    setTimeout(() => {
      setAnimationComplete(true);
    }, 1500);
  };

  // 鼠标移入某个solution元素
  const handleMouseEnterSolution = (index: number) => {
    setAnimationComplete(false);
    setSolActiveIdx(index);
    isFirst.current = index === 0;
  };

  // banner自动播放
  const startAutoPlay = () => {
    const id = setInterval(() => {
      if (carouselRef.current) {
        setCurrentSlide(prev => (prev + 1) % bannerList.length);
        carouselRef.current.next();
      }
    }, 4000);
    setIntervalId(id);
  };

  const stopAutoPlay = () => {
    // 停止自动播放
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  useEffect(() => {
    // 组件挂载时开始自动播放
    startAutoPlay();
    return stopAutoPlay;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      // 根据 currentSlide 更新轮播
      carouselRef.current.goTo(currentSlide);
    }
  }, [currentSlide]);

  const handleChange = (current: number) => {
    setCurrentSlide(current);
    stopAutoPlay();
    startAutoPlay();
  };

  const router = useRouter();
  if (router.isFallback) {
    return <Loading />;
  }

  return (
    <Layout>
      {/* banner幻灯片模块 */}
      <section className={style.advertise}>
        <Carousel
          dots={{
            className: style.dots,
          }}
          ref={carouselRef}
          autoplay={false}
          afterChange={handleChange}
        >
          {bannerList.map((item: IBannerList) => {
            const {
              key,
              content: { title, desc, mDesc, intro },
              btnJumpLink,
              isTryBtn,
            } = item;
            return (
              <div key={key}>
                <div className={cn(style.advertiseItem, style[key])}>
                  <div className={cn(style.content, style[key])}>
                    <p className={style.title}>{title}</p>
                    <p className={style.desc}>{isMobile ? mDesc || desc : desc}</p>
                    {intro && <p className={style.intro}>{intro}</p>}
                  </div>
                  <div className={style.btnWrapper}>
                    {isTryBtn && (
                      <CustomLink isNextLink={false} href={'/try'}>
                        <Button category="banner" type="primary" color="default" className={style.btnTry}>
                          立即试用
                        </Button>
                      </CustomLink>
                    )}
                    {btnJumpLink && (
                      <CustomLink isNextLink={false} href={btnJumpLink}>
                        <Button category="banner" type="primary">
                          查看详情
                        </Button>
                      </CustomLink>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </section>
      {/* 点击视频，了解小鱼易连 */}
      <section className={style.videoWrapper}>
        <video
          className={style['for-pc']}
          autoPlay
          muted
          loop
          src={muteMp4Url}
          poster={mutePoster}
          webkit-playsinline="true"
          playsInline={true}
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-ignore-metadata="true"
          preload="auto"
        >
          您的浏览器不支持video
        </video>
        <div className={style.subImg}>
          <p className={style.title}>点击视频，了解小鱼易连</p>
          <Image className={style.playBtn} src="/v2/images/home/pc_play.png" alt="playBtn" onClick={() => playVideo(true)} />
        </div>
        <div className={style.shadow} />
      </section>
      {/* 核心介绍模块 */}
      <section className={style.coreIntroductions}>
        {coreIntroductions.map((item: ICoreIntroductions) => {
          return (
            <CustomLink isNextLink={false} href={item.descJumpUrl} key={item.key}>
              <Transition delay={0.5}>
                <div className={cn(style.coreIntroduction, style[item.key])} style={{ backgroundImage: `url(${urlPrefix}${item.key}.jpg)` }}>
                  <Transition delay={0.8}>
                    <h1 className={style.title}>{item.title}</h1>
                  </Transition>
                  <Transition delay={1.1}>
                    <CustomLink className={style.desc} isNextLink={false} href={item.descJumpUrl}>
                      <JumpIntro desc={item.desc} />
                    </CustomLink>
                  </Transition>
                </div>
              </Transition>
            </CustomLink>
          );
        })}
      </section>

      {/* 地图模块 */}
      <section className={style.mapWrapper}>
        <video
          className={style['for-pc']}
          autoPlay
          muted
          loop
          src={`${prefix}/PC/map_video.mp4`}
          poster={`${prefix}/PC/map_poster.jpg`}
          webkit-playsinline="true"
          playsInline={true}
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-ignore-metadata="true"
          preload="auto"
        >
          您的浏览器不支持video
        </video>
        <div className={cn(style['for-mobile'], style.mapContent)}>
          <CdnImage className={style.mapImg} src="https://sdk-source.xylink.com/website/assets/home/M/test_animate.gif" alt="map" />
          <div className={style.mapText}>
            <Transition delay={0.5}>
              <p dangerouslySetInnerHTML={{ __html: '每一天\n在全球近百个国家，近千座城市\n超过30万次的远程视频连线\n通过小鱼易连开启'.replace(/\n/g, '<br/>') }}></p>
            </Transition>
          </div>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.numberContent}>
            {mapNumbers.map(item => (
              <Transition delay={0.8} key={item.key}>
                <div className={cn(style.numberItem, style[item.key])}>
                  <span className={style.number}>{item.number}</span>
                  <span className={style.text}>{item.text}</span>
                </div>
              </Transition>
            ))}
          </div>
          <div className={cn(style['for-pc'], style.textContent)}>
            <Transition delay={0.8}>
              <p dangerouslySetInnerHTML={{ __html: '每一天，在全球近百个国家，近千座城市，\n超过30万次的远程视频连线，通过小鱼易连开启。'.replace(/\n/g, '<br/>') }}></p>
            </Transition>
          </div>
        </div>
      </section>
      {/* 行业解决方案 */}
      <section className={style.solWrapper}>
        <Transition delay={0.5}>
          <h1 className={style.bigTitle}>行业解决方案</h1>
        </Transition>
        <Transition delay={0.8} onAnimationComplete={handleAnimationComplete}>
          <div className={style.contentWrapper}>
            {solutions.map((item: ISolution, index: number) => {
              const { key, title, desc } = item;
              // 第一个元素在视口内时激活,并且此时鼠标没经过任何item,第一个元素添加箭头动画
              const isInitial = animationComplete && solActiveIdx === 0 && index === 0;
              const isActive = solActiveIdx === index;
              const className = `${style.solution} ${((isActive && index !== 0) || (index === 0 && isFirst.current)) && !isMobile && style.active} ${isInitial && !isMobile && style.initialActive}`;

              return (
                <div
                  key={key}
                  className={className}
                  onMouseEnter={() => handleMouseEnterSolution(index)}
                  style={
                    {
                      '--close-image-url': `url(${prefix}/${(isMobile && 'M/') || ''}solutions/close/${key}.jpg)`,
                      '--open-image-url': `url(${prefix}/solutions/open/${key}.jpg)`,
                    } as React.CSSProperties
                  }
                >
                  <CustomLink isNextLink={false} href={item.link} className={style.itemContentWrapper}>
                    <div className={style.content}>
                      <div className={style.intro}>
                        <p className={style.title}>{title}</p>
                        <p className={style.desc}>{desc}</p>
                        <CustomLink isNextLink={false} href={item.link} className={style.jumpIntro}>
                          <JumpIntro />
                        </CustomLink>
                      </div>
                      <div className={style.arrow} style={{ backgroundImage: `url(${prefix}/solutions/open/${key}_arrow.png)` }}></div>
                    </div>
                  </CustomLink>
                </div>
              );
            })}
          </div>
        </Transition>
      </section>
      {/* 新闻模块 */}
      <section className={style.newsWrapper}>
        <header>
          <Transition delay={0.5}>
            <div className={style.title}>公司新闻</div>
          </Transition>

          <Transition delay={0.8}>
            <CustomLink isNextLink={false} href={'/news'}>
              <div className={style.subTitle}>
                <span>更多新闻</span>&nbsp;&gt;
              </div>
            </CustomLink>
          </Transition>
        </header>
        <Transition delay={1}>
          <div className={style.inner}>
            <Row gutter={[{ xs: 0, sm: 0, md: 20 }, 0]}>
              {news.filter(Boolean).map((item: any) => {
                return (
                  <Col key={item.id} span={24} md={8} lg={8}>
                    <CustomLink isNextLink={false} href={`/news/${item.id}`}>
                      <div className={style.news}>
                        <img className={style.poster} src={item.newsCover} alt={item.newsTitle} />
                        <div className={style.detail}>
                          <p className={style.title}>{item.newsTitle}</p>
                          <CustomLink isNextLink={false} href={`/news/${item.id}`} className={style.jumpDetails}>
                            <JumpIntro />
                          </CustomLink>
                        </div>
                      </div>
                    </CustomLink>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Transition>
      </section>
      {/* 合作伙伴 */}
      <section className={style.partnerWrapper}>
        <div className={style.title}>和他们一起选择小鱼易连</div>
        <CdnImage className={style.partnersImg} src={`${urlPrefix}partners.jpg`} alt="partners" />
      </section>
      {/* 联系我们 */}
      <ContactBtnGroup />
      <Modal
        title=" "
        closeIcon={<Image className={style.closeIcon} src="/v2/images/home/live-closer.png" alt="font" />}
        visible={videoVisible}
        centered={true}
        footer={null}
        className={style.videoModal}
        onCancel={() => playVideo(false)}
      >
        <video ref={videoRef} className={style.videoPlayer} controls autoPlay src={mp4Url}>
          您的浏览器不支持video
        </video>
      </Modal>
    </Layout>
  );
}
export const getStaticProps: GetStaticProps = async context => {
  const res = await actionServer.getHomeNews();
  return {
    props: {
      news: res || [],
    },
    revalidate: 30,
  };
};
