import style from './index.module.scss';
import cn from 'classnames';
import { CSSProperties } from 'react';

type IFitMode = 'fill-width' | 'fill-height';
interface IProps {
  styles?: CSSProperties;
  className?: string;
  src: string;
  alt?: string;
  // objectFit?: 'cover' | 'contain' | 'auto';
  fitMode?: IFitMode;
  click?: () => void;
}

export default function Home(props: IProps) {
  const { className, styles, alt = 'img', src, fitMode = 'fill-width', click } = props;

  return (
    <div className={cn(style.container, !!className && className)} style={styles ?? {}} onClick={click}>
      <img className={cn(style.img, style[fitMode])} alt={alt} src={src} />
    </div>
  );
}
