import { useState, useCallback, useEffect } from 'react';
const UseResize = () => {
  const [size, setSize] = useState({
    width: 0,
  });

  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [onResize]);
  return size;
};

export default UseResize;
