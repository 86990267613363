import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import style from './index.module.scss';
export interface TProps {
  animate?: 'fadeIn' | 'fly' | 'none';
  children: ReactNode;
  delay?: number;
  offset?: number;
  // 动画完成回调
  onAnimationComplete?: () => void;
}
const classEnum = {
  fadeIn: style.fade,
  fly: style.fly,
  none: '',
};
/*
 * @param offset 修改间距触发元素动画时机
 *
 */

const Transition = (prop: TProps) => {
  const { animate = 'fadeIn', children, delay = 0, offset = 0, onAnimationComplete } = prop;
  const container = useRef<HTMLDivElement>(null);
  const isFirst = useRef(true);
  const onScroll = useCallback(() => {
    const clientScreen = document.documentElement.clientHeight;
    if (container.current) {
      const { top } = container.current.getBoundingClientRect();
      const classList = Array.from(container.current.classList || []);
      /* 当元素出现在视口当中 */
      if (top + offset < clientScreen) {
        isFirst.current && onAnimationComplete?.(); // 动画完成回调
        isFirst.current = false;

        if (!classList.find(item => item === animate)) {
          container.current['classList']?.add?.(style[animate]);
        }
      }
    }
  }, [animate, offset, onAnimationComplete]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  /* 移动端时 无滚动时 触发动画*/
  useEffect(() => {
    if (!container.current?.scrollTop) {
      onScroll();
    }
  }, [onScroll]);
  return (
    <div ref={container} className={classEnum[animate]} style={{ animationDelay: delay + 's' }}>
      {children}
    </div>
  );
};

export default Transition;
